import React from "react";
import nfe from "../../assets/image/principal/png/NuvemFiscalNFe.png";
import "./CardContent.scss";
import ZoomImage from "../../components/ZoomImage";
// import ImgsViewer from "react-images-viewer";

const Nfe = ({ className, ...rest }) => {

  return (
    <div className={`card-section`} {...rest} id="nfe">
      <div className="container container-section">
        {/* Section Title */}
        <div
          data-aos="fade-right"
          data-aos-delay={500}
          className="text-content"
        >
          <h2  style={{color: "rgb(61, 59, 148)"}}>
            NF-e
            </h2>
            <p className="text-section">
            Emitir Notas Fiscais Eletrônicas sem ajuda de uma API fiscal 
            eficiente exige esforço de mão de obra e, principalmente, 
            tempo para lidar com burocracias na hora de se comunicar 
            com a Receita Federal.
            </p>
            <br/>
            <p className="text-section">
            Com a Nuvem Fiscal, você tem uma solução pronta para fazer 
            todo esse serviço por você. É só integrar ao seu software de 
            gestão e pronto, você já está pronto para emitir NFe’s sem se 
            preocupar com desenvolvimento ou burocracias de legislação. 
            </p>
            <br/>
            <p className="text-section">
            Evite trabalhos desnecessários, terceirize esse serviço de 
            forma rápida e inteligente.
            </p>
    
            <div className="btn-group d-inline-block mt-10">
              <a className="btn btn-2 rounded-5" style={{backgroundColor: "rgb(61, 59, 148)", color: "white"}} href={"https://console.nuvemfiscal.com.br"}>
                 Comece Agora
              </a>
              </div>
              <div className="btn-group d-inline-block mt-10 ml-lg-5">
                <a className="btn btn-2 rounded-5" style={{backgroundColor: "rgb(250, 128, 114)", color: "white"}} href={"https://dev.nuvemfiscal.com.br/docs/nfe"}>
                  Saiba mais
                </a>
              </div>
            </div>
            <ZoomImage src={nfe} alt='ok' />  
      </div>
    </div>
  );
};

export default Nfe;
