import React from "react";
import Card from "../../components/FreatureCard";
import iconCEP from "../../assets/image/principal/Icons/IconCEP.svg";
import iconCNPJ from "../../assets/image/principal/Icons/IconCNPJ.svg";
import iconNFC from "../../assets/image/principal/Icons/IconNFC.svg";
import iconNFE from "../../assets/image/principal/Icons/IconNFE.svg";
import iconNFS from "../../assets/image/principal/Icons/IconNFS.svg";

const Feature = ({ className, ...rest }) => {
  const featureCards = [
    {
      image: iconCNPJ,
      title: "CNPJ",
      text:
        "Consulte as informações de qualquer CNPJ de forma rápida, fácil e assertiva",
      link: "/#cnpj"
    },
    {
      image: iconNFE,
      title: "NF-e",
      text:
        "Emita a Nota Fiscal Eletrônica dos seus pedidos sem complicações",
      link: "/#nfe"
    },
    {
      image: iconNFS,
      title: "NFS-e",
      text:
        "Deixe que a Nuvem Fiscal emita suas Notas Fiscais de Serviço por você",
        link: "/#nfse"
    },
    {
      image: iconNFC,
      title: "NFC-e",
      text:
        "Notas Fiscais Eletrônicas para Clientes? A Nuvem Fiscal emite para você!",
      link: "/#nfce"
    },
    {
      image: iconCEP,
      title: "CEP",
      text:
        "Consulta de CEP rápida e fácil",
      link: "/#cep"
    },
    {
      image: iconCNPJ,
      title: "CT-e",
      text:
        "Emissão de CT-e rápida e fácil",
      link: "/#cte"
    },
    {
      image: iconNFS,
      title: "MDF-e",
      text:
        "Emissão de MDF-e rápida e fácil",
      link: "/#mdfe"
    },
    {
      image: iconNFE,
      title: "NFCom",
      text:
        "Emita Nota Fiscal Fatura de Serviço de Comunicação Eletrônica",
      link: "/#nfcom"
    },
  ];

  return (
    <div className={className} {...rest}>
      {/* feature section */}
      <div className="container">
        {/* Section Title */}
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-7 col-md-9">
            <div
              className="text-center mb-8 mb-lg-14"
              data-aos="zoom-in"
              data-aos-delay={500}
            >
              <h2 className="font-size-10 mb-6 letter-spacing-n83" style={{color: "rgb(51, 57, 141)"}}>
              Conheça as soluções feitas para você
              </h2>
            </div>
          </div>
          <p className="font-size-6 mb-0 col-xl-6 text-center" style={{height: "150px"}}>
              Chega de burocracia fiscal e complicação na hora de desenvolver
              soluções eficientes, a Nuvem Fiscal faz isso por você!
          </p>
        </div>
        {/* End Section Title */}
      </div>
      {/* feature*/}
      <div className="row justify-content-center ml-md-8 mr-md-8" style={{gap: "0 15px"}}>
       
            {featureCards.map(({ image, title, text, link }, index) => {
              return (
                <Card image={image} title={title} text={text} link={link} key={index} />
              );
            })}
      </div>
      {/* End feature*/}
      <div className="row justify-content-center">
        <div className="col-12">
          <div
            className="mt-10 mt-lg-12 text-center"
            data-aos="zoom-in"
            data-aos-delay={1000}
          >
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
