import {Controlled as ControlledZoom} from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import React from 'react';
import styled from 'styled-components';


const ImageContainer = styled.div`
  width:50%;
  flex-basis: 60%;
  display: flex;
  justify-content: start;
  @media (max-width: 630px){
    width:100%;
    order:-1;
  }
`

function ZoomImage({ src, alt, className }){
    let [zoomedImageId, setZoomedImageId] = React.useState(null);
  const handleZoomChange = React.useCallback((id,shouldZoom) => {
    if (!shouldZoom) {
      setZoomedImageId(null);
    } else {
      setZoomedImageId(id);
    }
  }, []);

    return(
      <ImageContainer className={className}>
        <ControlledZoom isZoomed={zoomedImageId === true} onZoomChange={(shouldZoom) => handleZoomChange(true, shouldZoom)}>
            <img
            alt={`${alt}`}
            src={src}
            width="100%"
            />
        </ControlledZoom>
      </ImageContainer>
    )
}

export default ZoomImage;