import React from "react";
import imageOne from "../../assets/image/principal/png/NuvemFiscalHome4detalhelateraldireitaCortado.png";
import imageTwo from "../../assets/image/principal/png/NuvemFiscalHome4Cortado.png";
import "./Content.scss";

const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={`content-section ${className}`} {...rest}>
      <div className="container-section">
        <div className="imagem">
          <img  src={imageTwo} alt=""/>
        </div>
          <div
            className="texto"
            data-aos="fade-right"
            data-aos-delay={500}  
          >
            <img  src={imageOne} alt=""/>
              <h2 className="titulo" style={{color: "rgb(61, 59, 148)"}}>
                De desenvolvedor para desenvolvedor
              </h2>
              <br/>
              <p className="text-section subtitulo">
                A Nuvem fiscal foi feita dentro da premissa dev to dev. 
                Nascemos para terceirizar seu problema e descomplicar a sua rotina de forma prática e eficiente.
              </p>
              <div className="mt-9">
                <a className="btn btn-2 rounded-5" style={{backgroundColor: "rgb(250, 128, 114)", color: "white"}} href={"https://dev.nuvemfiscal.com.br/docs"}>
                  Saiba Mais!
                </a>
              </div>
          </div>
          <div/>
        <div/>
      </div>
    </div>
  );
};

export default ContentTwo;
