import React from "react";
import cep from "../../assets/image/principal/png/NuvemFiscalCEP.png";
import ZoomImage from "../../components/ZoomImage";
import "./CardContent.scss";


const Mdfe = ({ className, ...rest }) => {
  return (
    <div className={`card-section `} {...rest}  id="mdfe">
      <div className="container container-section">
        {/* Section Title */}
        <div
          data-aos="fade-right"
          data-aos-delay={500}
          className="text-content"
        >
          <h2  style={{color: "#274360"}}>
            MDF-e
            </h2>
            <p className="text-section">
            Quer emitir e consultar informações sobre Manifesto Eletrônico de Documentos Fiscais (MDF-e)?
            A Nuvem Fiscal oferece essa solução totalmente pronta para ser aplicada no seu sistema!
            </p>

            <div className="btn-group d-inline-block mt-10">
              <a className="btn btn-2 rounded-5" style={{backgroundColor: "rgb(61, 59, 148)", color: "white"}} href={"https://console.nuvemfiscal.com.br"}>
                 Comece Agora
              </a>
              </div>
            <div className="btn-group d-inline-block mt-10 ml-lg-5">
              <a className="btn btn-2 rounded-5" style={{backgroundColor: "rgb(250, 128, 114)", color: "white"}} href={"https://dev.nuvemfiscal.com.br/docs/mdfe"}>
                 Saiba mais
              </a>
              </div>
            </div>

          <ZoomImage src={cep} alt=""/>
      </div>
    </div>
  );
};

export default Mdfe;
