import React from "react";
// import imgRodape from "../../assets/image/principal/png/NuvemFiscalRodape.png";
import './Planos.scss';
import PricingTable from '../../components/Pricing';


const Pricing = ({ className, ...rest }) => {
  return (
    <div className={`pricing-section position-relative ${className}`} {...rest}>
      <div className="container plan-section">
        <PricingTable fontColor="rgb(79, 61, 161)" btnBackground="rgb(250, 128, 114)" btnColor="#274360"/>
        {/* <div className="img-rodape">
          <img
            src={imgRodape}
            alt=""
            data-aos="fade-left"
            data-aos-delay={500}
          />
        </div> */}
      </div>
    </div>
  );
};

export default Pricing;
