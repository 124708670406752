import React from "react";

import "./Content.scss";

import imageOne from "../../assets/image/principal/png/NuvemFiscalNFSe2.png";

import iconOne from "../../assets/image/principal/Icons/IconProgramacao.svg";
import iconTwo from "../../assets/image/principal/Icons/IconFeminino.svg";
import iconThree from "../../assets/image/principal/Icons/IconCaneta.svg";
import iconFour from "../../assets/image/principal/Icons/IconEngrenagem.svg";
import ZoomImage from "../../components/ZoomImage";

const ContentThree = ({ className, ...rest }) => {
  const widgetArray = [
    {
      title: "Compatível com PHP, C#, Java e várias outras linguagens",
      icon: iconOne,
    },
    {
      title: "Gerencie sua API de forma independente",
      icon: iconTwo,
    },
    {
      title:
        "Evite burocracias fiscais",
      icon: iconThree,
    },
    {
      title:
        "Não fique refém de equipes para manutenção e suporte",
      icon: iconFour,
    },
  ];

  return (
    <div className={`content-section`} {...rest} style={{backgroundColor: "rgb(227, 233, 252)"}}>
      <div className="container-section">
            <div className="texto">
              {/* Section Title */}
              <div
                className="mb-5"
                data-aos="fade-right"
                data-aos-delay={500}
              >
                <h2 className="font-size-10 mb-8" style={{color: "rgb(250, 128, 114)"}}>
                Por que escolher a Nuvem Fiscal?
                </h2>
                <p className="text-section subtitulo">
                Ainda está em dúvida sobre qual é a API Fiscal ideal para 
                descomplicar? Confira essas vantagens.

                </p>
              </div>
              {/* End Section Title */}
              <div className=" text-section" data-aos="fade-right" data-aos-delay={800}>
                {widgetArray.map(({ title, icon }, index) => {
                  return (
                    <div className="d-flex align-items-center mb-6" key={index}>
                      <div className="square-59 rounded-10 mr-6" style = {{backgroundColor: "rgb(79, 73, 163)"}}>
                        <img className="full-size" src={icon} alt="" />
                      </div>
                      <p
                        className="font-size-6 text-muted mb-0"
                        dangerouslySetInnerHTML={{ __html: title }}
                      ></p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="imagem">
              <ZoomImage className='img-content-three' src={imageOne} alt=""/>
            </div>
      </div>
    </div>
  );
};

export default ContentThree;
