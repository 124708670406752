import React from "react";
import nfcom from "../../assets/image/principal/png/NuvemFiscalNFCom.png";
import ZoomImage from "../../components/ZoomImage";
import "./CardContent.scss";

const Nfcom = ({ className, ...rest }) => {
  return (
    <div className={`card-section`} {...rest} id="nfcom">
      <div className="container container-section">
        {/* Section Title */}

        <ZoomImage src={nfcom} alt="" />
        <div
          data-aos="fade-right"
          data-aos-delay={500}
          className="text-content"
        >
          <h2  style={{color: "rgb(250, 128, 114)"}}>
            NFCom
          </h2>
          <br/>
            <p className="text-section">
            Com a Nuvem Fiscal você já pode emitir a Nota Fiscal Fatura de Serviço de Comunicação Eletrônica (NFCom),
            além é claro de poder consultar, cancelar e armazenar esse documentos com toda facilidade.
            </p>
            <br/>
            <div className="btn-group d-inline-block mt-10">
              <a className="btn btn-2 rounded-5" style={{backgroundColor: "rgb(61, 59, 148)", color: "white"}} href={"https://console.nuvemfiscal.com.br"}>
                Comece Agora
              </a>
            </div>
            <div className="btn-group d-inline-block mt-10 ml-lg-5">
                <a className="btn btn-2 rounded-5" style={{backgroundColor: "rgb(250, 128, 114)", color: "white"}} href={"https://dev.nuvemfiscal.com.br/docs/nfcom"}>
                  Saiba mais
                </a>
             </div>
          </div>
      </div>
    </div>
  );
};

export default Nfcom;
