import React from "react";
import nfce from "../../assets/image/principal/png/NuvemFiscalNFCe.png";
import ZoomImage from "../../components/ZoomImage";
import "./CardContent.scss";


const Nfce = ({ className, ...rest }) => {
  return (
    <div className={`card-section `} {...rest} id="nfce">
      <div className="container container-section">
        {/* Section Title */}
        <div
          data-aos="fade-right"
          data-aos-delay={500}
          className="text-content"
        >
          <h2  style={{color: "rgb(61, 59, 148)"}}>
            NFC-e
            </h2>
            <p className="text-section">
            Precisa emitir Notas Fiscais ao Consumidor? A Nuvem Fiscal 
            também faz isso por você!
            </p>
            <br/>
            <p className="text-section">
            Contar com uma API Fiscal eficiente garante que todo o 
            processo de integração, emissão e autorização de NFC-e seja 
            uma tarefa muito mais simples e rápida.  
            </p>
            <br/>
            <p className="text-section">
            E o melhor? Para começar a usar é só fazer o seu cadastro e 
            começar a testar gratuitamente!
            </p>
            <div className="btn-group d-inline-block mt-10">
              <a className="btn btn-2 rounded-5" style={{backgroundColor: "rgb(61, 59, 148)", color: "white"}} href={"https://console.nuvemfiscal.com.br"}>
                 Comece Agora
              </a>
              </div>
              <div className="btn-group d-inline-block mt-10 ml-lg-5">
              <a className="btn btn-2 rounded-5" style={{backgroundColor: "rgb(250, 128, 114)", color: "white"}} href={"https://dev.nuvemfiscal.com.br/docs/nfce"}>
                 Saiba mais
              </a>
              </div>
            </div>
            <ZoomImage src={nfce} alt="" />
      </div>
    </div>
  );
};

export default Nfce;
