import React, { useState} from 'react';
import styled from "styled-components";

const BtnWrapper = styled.div`
    .btn-reset {
        color: #274360;
        &.active {
            color: white;
        }
    }
`;

export const PriceInfo = {
    Gratis: '0',
    Fiscal1Mensal: '180',
    Fiscal1Anual: '150',
    Fiscal2Mensal: '600',
    Fiscal2Anual: '500',
}

export default function Pricing({ fontColor, btnBackground, btnColor }){
    const [plan, setPlan] = useState("anual");
    const PricingData = [
        {
          title: "Grátis",
          list: ["1.000 operações fiscais", "50.000 consultas de CNPJ", "100.000 consultas de CEP", "CNPJs ilimitados"],
          price: plan === "mensal" ? PriceInfo.Gratis : PriceInfo.Gratis,
          
        },
        {
          title: "Fiscal I",
          list: ["10.000 operações fiscais", "150.000 consultas de CNPJ", "200.000 consultas de CEP", "CNPJs ilimitados"],
          price: plan === "mensal" ? PriceInfo.Fiscal1Mensal : PriceInfo.Fiscal1Anual
        },
        {
          title: "Fiscal II",
          list: ["100.000 operações fiscais", "500.000 consultas de CNPJ", "700.000 consultas de CEP", "CNPJs ilimitados"],
          price: plan === "mensal" ? PriceInfo.Fiscal2Mensal : PriceInfo.Fiscal2Anual
        },
    ];

    return(
        <div>
            <div className="mb-13 mb-lg-13">
                <div className="row align-items-center justify-content-center">
                    <div className="col-xl-5 col-lg-6 col-md-10">
                        <div
                            className="mb-10 mb-lg-0 text-center text-lg-left"
                            data-aos="fade-right"
                            data-aos-delay={500}
                        >
                            <h2 className="font-size-9 pr-md-10 pr-xl-0 mb-0" style = {{color: fontColor}}>
                            Planos disponíveis
                            </h2>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6 col-md-6 text-center text-md-right">
                        <div
                            className="btn-section"
                            data-aos="fade-left"
                            data-aos-delay={500}
                        >
                            <BtnWrapper className="btn-pricing-group btn-toggle-square active mx-3 rounded-10" style={{backgroundColor: fontColor}}>
                                <button
                                    className={`text-break btn-reset focus-reset ${plan ===
                                    "anual" && "active"}`}
                                    onClick={() => setPlan("anual")}
                                >
                                    Anual
                                </button>
                                <button 
                                    className={`text-break btn-reset focus-reset ${plan ===
                                    "mensal" && "active"}`}
                                    onClick={() => setPlan("mensal")}
                                >
                                    Mensal
                                </button>
                            </BtnWrapper>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-body">
                <div className="row justify-content-center">
                    {PricingData.map(({ title, list, price }, index) => {
                        return (
                            <div className="col-lg-12 col-sm-6" key={index}>
                                <div
                                    className="border-top pt-10 pb-8"
                                    data-aos="fade-up"
                                    data-aos-delay={500}
                                >
                                    <div className="row text-center text-sm-left">
                                        <div className="col-lg-3">
                                            <div className="mb-5 mb-lg-0">
                                                <h3 className="font-size-7" style = {{color: fontColor}}>{title}</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="">
                                                <ul className="list-unstyled font-size-6">
                                                    {list.map((item, index) => (
                                                    <li
                                                        className="heading-default-color mb-4 text-muted"
                                                        key={index}
                                                    >
                                                        {item}
                                                    </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="pr-lg-15 pr-xl-10 mb-5 mb-lg-0">
                                                <h2 className="mb-0 font-size-11 font-weight-medium" style={{color:fontColor}}>
                                                    R$ {price}
                                                </h2>
                                                <p className="mb-0 font-size-5" style={{color:fontColor}}> 
                                                </p>
                                                <p className="mb-0 font-size-5"> 
                                                  {price <= 0 && "Comece sem preocupação!"}
                                                  {plan === "anual" && price > 0 && "Por mês, cobrado anualmente"}
                                                  {plan === "mensal" && price > 0 && "Por mês"}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="mb-5 mb-lg-0 mt-4 texts-sm-left text-lg-right">
                                                <a
                                                    className="btn btn-2 border-width-2 rounded-5 heading-default-color"
                                                    href="https://console.nuvemfiscal.com.br"
                                                    style={{backgroundColor: btnBackground, color: btnColor}}
                                                >
                                                    Comece agora
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}