import React from "react";
import cnpj from "../../assets/image/principal/png/NuvemFiscalCNPJ.png";
import ZoomImage from "../../components/ZoomImage";
import "./CardContent.scss";

const Cnpj = ({ className, ...rest }) => {
  return (
    <div className={`card-section `} {...rest} id="cnpj">
      <div className="container container-section">
        {/* Section Title */}
        <ZoomImage src={cnpj} alt=""/>
        <div
          data-aos="fade-right"
          data-aos-delay={500}
          className="text-content"
        >
          <h2  style={{color: "rgb(250, 128, 114)"}}>
            CNPJ
          </h2>
          <br/>
            <p className="text-section">
            Com a Nuvem Fiscal, seu sistema pode consultar dados e 
            preencher formulários de forma automatizada, economizando 
            tempo de burocracias e facilitando a rotina do usuário.
            </p>
            <br/>
            <div className="btn-group d-inline-block mt-10">
              <a className="btn btn-2 rounded-5" style={{backgroundColor: "rgb(61, 59, 148)", color: "white"}} href={"https://console.nuvemfiscal.com.br"}>
                Comece Agora
              </a>
              </div>
              <div className="btn-group d-inline-block mt-10 ml-lg-5">
              <a className="btn btn-2 rounded-5" style={{backgroundColor: "rgb(250, 128, 114)", color: "white"}} href={"https://dev.nuvemfiscal.com.br/docs/cnpj"}>
                 Saiba mais
              </a>
              </div>
            </div>
      </div>
    </div>
  );
};

export default Cnpj;
