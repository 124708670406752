import React from "react";
import styled from 'styled-components';

const Wrapper = styled.a`
  width:350px;
  cursor:pointer;

  @media screen and (max-width: 350px) {
    width:100%;
  }
`


const FeatureCard = ({ image, title, text, link }) => {
  return (
    <div>
    <Wrapper href={link} className="d-flex rounded-10 pt-7 pl-7 pr-7 pb-7 mb-8" style={{backgroundColor: "rgb(215, 225, 255)", height: "165px"}}>
      <div className="mb-8 mr-8 bg-white circle-55">
        <img className="full-size" src={image} alt="" />
      </div>
      <div className="">
        <h4 className="font-size-7 mb-0" style={{color: "rgb(51, 57, 141)"}}>{title}</h4>
        <p className="font-size-5 line-height-28 text-stone mr-xl-7 mb-0">
          {text}
        </p>
      </div>
    </Wrapper>
    </div>
  );
};

export default FeatureCard;
