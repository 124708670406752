import React from "react";
import nfse from "../../assets/image/principal/png/NuvemFiscalNFSe.png";
import ZoomImage from "../../components/ZoomImage";
import "./CardContent.scss";

const Nfse = ({ className, ...rest }) => {
  return (
    <div className={`card-section`} {...rest} id="nfse">
      <div className="container container-section">
        {/* Section Title */}

        <ZoomImage src={nfse} alt="" />
        <div
          data-aos="fade-right"
          data-aos-delay={500}
          className="text-content"
        >
          <h2  style={{color: "rgb(250, 128, 114)"}}>
            NFS-e
          </h2>
          <br/>
            <p className="text-section">
            Emitir Notas Fiscais de Serviço podem trazer uma dor de 
            cabeça desnecessária na hora de desenvolver seu módulo 
            fiscal de emissão e ao se comunicar com os órgãos 
            governamentais.
            </p>
            <br/>
            <p className="text-section">
            Com a Nuvem Fiscal, você não precisa se preocupar com mais 
            de 90% desse processo. É só fazer a integração com o seu 
            sistema de gestão e está pronto para emitir! 
            </p>
            <br/>
            <div className="btn-group d-inline-block mt-10">
              <a className="btn btn-2 rounded-5" style={{backgroundColor: "rgb(61, 59, 148)", color: "white"}} href={"https://console.nuvemfiscal.com.br"}>
                Comece Agora
              </a>
            </div>
            <div className="btn-group d-inline-block mt-10 ml-lg-5">
                <a className="btn btn-2 rounded-5" style={{backgroundColor: "rgb(250, 128, 114)", color: "white"}} href={"https://dev.nuvemfiscal.com.br/docs/nfse"}>
                  Saiba mais
                </a>
             </div>
          </div>
      </div>
    </div>
  );
};

export default Nfse;
