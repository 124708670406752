import React from "react";
import "./Content.scss";
import imageOne from "../../assets/image/principal/png/NuvemFiscalHome3.png";
import iconOne from "../../assets/image/principal/Icons/IconMoedas.svg";
import iconTwo from "../../assets/image/principal/Icons/IconPredios.svg";
import iconThree from "../../assets/image/principal/Icons/IconFeminino.svg";
import ZoomImage from "../../components/ZoomImage";

const ContentOne = ({ className, ...rest }) => {
  const widgetArray = [
    {
      title: "Reduza custos, tempo e esforço",
      icon: iconOne,
    },
    {
      title: "Integre com a Nuvem Fiscal em minutos",
      icon: iconTwo,
    },
    {
      title:
        "Trabalhe de forma independente, sem depender de uma equipe para integrar com a SEFAZ",
      icon: iconThree,
    },
  ];

  return (
    <div className={`content-section position-relative `} {...rest}>
      <div className="container-section">
        <div className="texto">
           {/* Section Title */}
            <div
              className="mb-5 mb-lg-13 pr-md-15 pr-xl-0"
               data-aos="fade-right"
              data-aos-delay={500}
              >
             <h2 className="font-size-10 mb-8" style={{color: "rgb(61, 59, 148)"}}>
               A API Fiscal ideal para descomplicar
             </h2>
             <p className="text-section subtitulo">
              Conheça os benefícios que fazem da Nuvem Fiscal a API.
             </p>
          </div>
          {/* End Section Title */}
          <div className="text-section" data-aos="fade-right" data-aos-delay={800}>
            {widgetArray.map(({ title, icon }, index) => {
               return (
                <div className="d-flex align-items-center mb-6" key={index}>
                  <div className="circle-59 mr-6" style = {{backgroundColor: "#274360"}}>
                     <img className="full-size" src={icon} alt="" />
                   </div>
                  <p
                    className="heading-default-color font-weight-medium mb-0"
                    style={{color: "rgb(61, 59, 148)"}}
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></p>
               </div>
               );
            })}
          </div>
        </div>
        <div className="imagem">
          <ZoomImage className='img-content-one' src={imageOne} alt=""/>
        </div>
      </div>
    </div>
  );
};

export default ContentOne;
